<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <DatePicker
          v-model="dateRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="日期范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table
      stripe
      :columns="tableColumns"
      :data="itemList"
      :loading="isLoading"
    ></Table>
    <Pager :total="itemListCount" :current.sync="page" />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"

export default {
  name: "AnalysisPlanPage",
  components: { ContentWrapper, Pager },
  data() {
    return {
      topLinks: [{ title: "投放分析", link: this.$route.path }],
      dateRange: ["", ""],
      itemList: [],
      itemListCount: 0,
      page: 1,
      tableColumns: [
        { title: "位置" },
        { title: "名称" },
        { title: "日期" },
        { title: "PV" },
        { title: "UV" },
        { title: "点击数" },
        { title: "点击后成交" },
      ],
      isLoading: false,
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {},
  },
}
</script>
